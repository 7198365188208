const trainingMessages = {
  part: 'partie | parties',
  autoEvaluation: 'auto-évaluation',
  steps: {
    intro: 'intro',
    lesson: 'cours',
    activity: 'activité'
  },
  status: {
    locked: 'verrouillé',
    todo: 'commencer',
    started: 'reprendre',
    finished: 'validé'
  },
  activity: {
    intro: {
      label: 'objectif de l’activité',
      rulesLabel: 'Consigne',
      warning: '<b>Votre score final ne vous est donné qu’à titre indicatif.</b> À l’issue de l’activité, vous pourrez continuer la formation et rejouer l’activité quand vous le souhaitez.'
    }
  },
  mprMenages: {
    aise: 'Supérieurs',
    intermediaire: 'Intermédiaires',
    modeste: 'Modestes',
    tresModeste: 'Très modestes'
  },
  resultAnnotations: {
    optimistic: 'Vous étiez peut-être un peu trop optimiste…',
    bravo: 'Bravo, vous avez progressé\xa0!',
    good: 'Vous êtes effectivement incollable\xa0!',
    progress: 'Ce n’est toujours pas ça…'
  },
  global: {
    stepIntroTitle: 'Objectifs de cette partie',
    goToActivity: 'débuter l’activité',
    goToLesson: 'accéder au cours',
    showResults: 'voir mes résultats',
    activityResult: 'Activité terminée',
    replayActivity: 'rejouer l’activité',
    replayLesson: 'revoir le cours',
    partFinished: 'Vous avez fini cette partie\xa0!',
    autoEvaluateHelp: 'Sélectionnez ci-dessus le niveau qui vous correspond.',
    quizzTitle: 'affirmation',
    points: 'Aucun point obtenu | 1 point obtenu | {count} points obtenus',
    train: 'entrainez-vous',
    moduleFinished: 'Vous avez fini ce module\xa0!'
  },
  module1: {
    name: 'Module 1',
    title: 'Sensibilisation à la rénovation énergétique\n' +
      'et aux financements des aides publiques',
    parts: {
      part1: 'Objectif : Rénovation énergétique',
      part2: 'Comment financer la rénovation énergétique\xa0?',
      part3: 'Zoom sur MaPrimeRénov’',
      part4: 'Zoom sur les CEE',
      part5: 'Valoriser les travaux de rénovation énergétique'
    }
  },
  module2: {
    name: 'Module 2',
    title: 'Accompagner une démarche CEE',
    parts: {
      part1: 'Se positionner dans l’écosystème des CEE',
      part2: 'Qu’est-ce qu’une opération éligible aux CEE\xa0?',
      part3: 'Monter un dossier CEE'
    }
  },
  module3: {
    name: 'Module 3',
    title: 'Accompagner une démarche MaPrimeRénov’',
    parts: {
      part1: 'L’écosystème MaPrimeRénov’',
      part2: 'Éligibilité et calcul du montant des aides MaPrimeRénov’',
      part3: 'Monter un dossier MaPrimeRénov’'
    }
  },
  finalTest: {
    testAlreadyPassed: 'Vous avez déjà passé le test avec succès',
    title: 'Testez vos connaissances et validez la formation\xa0!',
    label: 'Test de fin',
    intro: {
      rules: '<b>Le test se compose de 30 questions à choix multiple.</b><br>' +
        'Faites moins de 9 erreurs pour valider le test&nbsp;!',
      warning: 'Vous pouvez valider sans faire de sélection si vous pensez qu’aucun choix n’est correct.',
      goToActivity: 'passer le test'
    },
    multipleAnswer: 'Plusieurs réponses attendues',
    singleAnswer: 'Une seule réponse possible',
    resultBlock: {
      title: 'Résultat du test',
      errors: 'erreur | erreurs',
      fail: 'Vous ferez mieux au prochain essai\xa0!',
      userHomeButton: 'revenir au tableau de bord',
      retryButton: 'repasser le test',
      failLabel: 'Loupé…',
      successLabel: 'Félicitations\xa0!',
      success: '<b>Vous avez validé la formation</b> avec brio.<br>' +
        '<br>' +
        'Nous vous envoyons immédiatement <b>votre certificat de réussite</b> par email.',
      retry: 'repasser le test'
    },
    questions: {
      question1: {
        question: 'Quels sont les objectifs de la rénovation énergétique des bâtiments\xa0?',
        answers: {
          answer1: 'La diminution des émissions de gaz à effet de serre',
          answer2: 'La diminution de la consommation d’énergie',
          answer3: 'L’amélioration qualitative du parc immobilier national',
          answer4: 'La diminution du nombre de logements dits "passoires thermiques"',
          answer5: 'L’augmentation stricte du nombre de logements'
        }
      },
      question2: {
        question: 'Qu’est-ce qu’une passoire énergétique\xa0?',
        answers: {
          answer1: 'Un logement classé G ou F au diagnostic de performance énergétique',
          answer2: 'Un logement énergivore à cause de la vétusté du chauffage et du manque d’isolation',
          answer3: 'Un logement dont les factures énergétiques représentent une part très importante des dépenses du ménage.',
          answer4: 'Un logement qui consomme plus de 450 kWh/m² par an en énergie finale.'
        }
      },
      question3: {
        question: 'Parmi les éléments suivants, lequel n’est pas inclus dans une démarche de rénovation énergétique\xa0:',
        answers: {
          answer1: 'L’isolation des murs',
          answer2: 'L’isolation des planchers bas',
          answer3: 'L’isolation de la toiture',
          answer4: 'Le remplacement des menuiseries extérieures',
          answer5: 'La ventilation',
          answer6: 'La production de chauffage / eau chaude sanitaire',
          answer7: 'Le remplacement des menuiseries intérieures / la peinture'
        }
      },
      question4: {
        question: 'Quelles aides peuvent financer des travaux de rénovation d\'ampleur\xa0:',
        answers: {
          answer1: 'CEE',
          answer2: 'Coup de Pouce',
          answer3: 'TVA à 5,5',
          answer4: 'Éco-prêt à taux zéro',
          answer5: 'MaPrimeRénov’'
        }
      },
      question6: {
        question: 'Quelles aides le dispositif MaPrimeRénov’ a remplacé en 2020\xa0:',
        answers: {
          answer1: 'Le crédit d’impôt pour la transition énergétique',
          answer2: 'Habiter mieux agilité',
          answer3: 'Éco-prêt à taux zéro',
          answer4: 'Habiter facile',
          answer5: 'Habiter sain'
        }
      },
      question7: {
        question: 'Quelle structure porte MaPrimeRénov’\xa0?',
        answers: {
          answer1: 'L’ANAH',
          answer2: 'L’ADEME',
          answer3: 'Le Ministère de l\'Écologie',
          answer4: 'Des acteurs privés'
        }
      },
      question8: {
        question: 'À quoi correspondent les couleurs dans le dispositif MaPrimeRénov’\xa0:',
        answers: {
          answer1: 'Aux niveaux de revenus des ménages',
          answer2: 'Aux différentes typologies de travaux éligibles',
          answer3: 'Aux différents secteurs d’activités des travaux'
        }
      },
      question9: {
        question: 'Depuis janvier 2022, les Certificats d’Économies d’Énergie sont entrés dans leur...',
        answers: {
          answer1: '3e période',
          answer2: '4e période',
          answer3: '5e période',
          answer4: '6e période'
        }
      },
      question10: {
        question: 'Quelle est la formule de calcul d’un certificat d’économies d’énergie&nbsp;?' +
          '<br>' +
          'CEE (kWh cumac) = …',
        answers: {
          answer1: 'Gain annuel * Coefficient d’actualisation (4%)',
          answer2: 'Gain annuel * Coefficient de dégradation (68%)',
          answer3: 'Consommation annuelle de l’opération * Coefficient d’actualisation (4%)',
          answer4: 'Consommation annuelle de l’opération * Coefficient de dégradation (68%)'
        }
      },
      question11: {
        question: 'Quelles étapes sont nécessaires pour obtenir des CEE dans le résidentiel individuel\xa0?',
        answers: {
          answer1: 'L’action d’incitation à la rénovation énergétique faite auprès des particuliers et mise en place par le demandeur de certificats d\'économies d\'énergie',
          answer2: 'Faire réaliser les travaux par un artisan ou une entreprise du bâtiment RGE',
          answer3: 'Avoir fait réaliser un diagnostic de performance énergétique du logement'
        }
      },
      question12: {
        question: 'Quel acteur instruit les dossiers et délivre les CEE\xa0?',
        answers: {
          answer1: 'Le Pôle National CEE',
          answer2: 'Les demandeurs de CEE (obligés, éligibles, délégataires)',
          answer3: 'Les artisans et entreprises du bâtiments',
          answer4: 'Les bénéficiaires'
        }
      },
      question13: {
        question: 'Parmi les situations suivantes et qui concernent les acteurs de l’écosystème des CEE, laquelle est erronée\xa0?',
        answers: {
          answer1: 'Les délégataires ne peuvent pas faire appel à des organismes mandataires pour transférer leurs droits et devoirs en matière d’économie d’énergie',
          answer2: 'Un bénéficiaire de CEE peut être une entreprise, un particulier ou une collectivité territoriale',
          answer3: 'Un obligé n’est pas obligé de faire appel à un délégataire pour produire des CEE'
        }
      },
      question14: {
        question: 'Que signifie le signe «\xa0RGE\xa0» Reconnu Garant de l\'Environnement\xa0?',
        answers: {
          answer1: 'C’est un signe de qualité délivré à une entreprise qui remplit certains critères lors de la réalisation de travaux d\'économie d\'énergie dans les logements',
          answer2: 'Cela signifie que l’entreprise reverse une partie de ses bénéfices à des actions en faveur de la sauvegarde de l\'environnement',
          answer3: 'C’est une certification acquise par un professionnel qui a suivi une formation portant sur la protection de l’environnement'
        }
      },
      question15: {
        question: 'Dans le cadre du dispositif des Certificats d’Économies d’Énergie, quelles sont les attentes des artisans et entreprises du bâtiment\xa0?',
        answers: {
          answer1: 'Réaliser des travaux de rénovation performants auprès des particuliers',
          answer2: 'Augmenter le nombre de chantiers de rénovation et leur rentabilité',
          answer3: 'Remplir des obligations réglementaires de réduction de consommation d’énergie',
          answer4: 'Faire baisser la consommation d\'énergie à l’échelle nationale'
        }
      },
      question16: {
        question: 'Par quelles actions est-il possible de produire des Certificats d’Économies d’Énergie\xa0?',
        answers: {
          answer1: 'La réalisation d’opérations standardisées',
          answer2: 'La réalisation d’opérations spécifiques',
          answer3: 'La participation à des programmes CEE',
          answer4: 'La réalisation d’économies sur la facture énergétique d’une entreprise ou d’une collectivité territoriale',
          answer5: 'La mise en œuvre d’une stratégie de décarbonation à l’échelle d’un parc immobilier'
        }
      },
      question17: {
        question: 'Quels éléments figurent dans une fiche d’opération standardisée\xa0?',
        answers: {
          answer1: 'Le secteur d’application',
          answer2: 'La dénomination',
          answer3: 'Les conditions pour la délivrance de certificats',
          answer4: 'La durée de vie',
          answer5: 'Le montant du certificat en kWh cumac',
          answer6: 'La durée d’application de la fiche'
        }
      },
      question18: {
        question: 'Parmi les secteurs suivants lesquels ne sont pas concernés par des opérations standardisées\xa0?',
        answers: {
          answer1: 'Agriculture',
          answer2: 'Bâtiment neuf',
          answer3: 'Bâtiment résidentiel',
          answer4: 'Bâtiment tertiaire',
          answer5: 'Industrie',
          answer6: 'Numérique',
          answer7: 'Réseaux',
          answer8: 'Transport'
        }
      },
      question19: {
        question: 'Quel est l\'objectif du dispositif "Coup de pouce"\xa0?',
        answers: {
          answer1: 'Massifier les opérations standardisées qui présentent un gain énergétique accru',
          answer2: 'Uniformiser l’obtention de certificats d’économies d’énergie (CEE) lors de la réalisation de travaux de rénovation, qu’importe le niveau de revenu du particulier',
          answer3: 'Massifier le recours aux certificats d’économies d’énergie (CEE) par les industriels'
        }
      },
      question20: {
        question: 'Quelles sont les spécificités d’une gestion de dossier MaPrimeRénov’\xa0?',
        answers: {
          answer1: 'Le professionnel doit avoir contractualisé en amont avec un obligé ou délégataire',
          answer2: 'C’est toujours le maître d\'ouvrage qui initie la demande d’aide',
          answer3: 'Il est possible de désigner un mandataire administratif et/ou financier'
        }
      },
      question21: {
        question: 'Quels acteurs peuvent porter le Rôle Actif et Incitatif (la pierre angulaire du dispositif CEE)\xa0?',
        answers: {
          answer1: 'Le Pôle National CEE',
          answer2: 'Les obligés',
          answer3: 'Les éligibles',
          answer4: 'Les délégataires',
          answer5: 'Les artisans et entreprises du bâtiments',
          answer6: 'Les bénéficiaires'
        }
      },
      question22: {
        question: 'Quels documents sont nécessaires au dépôt d’une demande CEE dans le secteur résidentiel\xa0?',
        answers: {
          answer1: 'Qualifications RGE',
          answer2: 'Cadre Contribution',
          answer3: 'Devis',
          answer4: 'Facture',
          answer5: 'Attestation sur l’Honneur'
        }
      },
      question23: {
        question: 'Quelle est la durée minimale entre la signature du devis et le début des travaux d\'isolation dans le cadre des CEE\xa0?',
        answers: {
          answer1: 'Il n’y a pas de durée minimale',
          answer2: '5 jours ouvrés',
          answer3: '7 jours francs',
          answer4: '14 jours'
        }
      },
      question24: {
        question: 'Qui peut faire appel à MaPrimeRénov’ pour financer ses travaux\xa0?',
        answers: {
          answer1: 'Un propriétaire occupant',
          answer2: 'Un syndicat de copropriété',
          answer3: 'Une Société Civile Immobilière (SCI)',
          answer4: 'Un propriétaire bailleur',
          answer5: 'Un locataire',
          answer6: 'Un bailleur social'
        }
      },
      questionTypeAidesMpr: {
        question: 'Quelles sont les types d’aides à la rénovation énergétique que propose MaPrimeRénov’\xa0?',
        answers: {
          answer1: 'Les aides à la décarbonation du chauffage',
          answer2: 'Les aides à l\'isolation',
          answer3: 'Les aides «\xa0Bâtiment Basse Consommation\xa0»',
          answer4: 'Les aides à la rénovation d’ampleur'
        }
      },
      question25: {
        question: 'Quel est l’objectif de rénovations d’ampleur en 2024 via le dispositif MaPrimeRénov’ Parcours Accompagné\xa0?',
        answers: {
          answer1: '70\xa0000',
          answer2: '200\xa0000',
          answer3: '500\xa0000'
        }
      },
      questionSaut4Classe: {
        question: 'Dans le cas d’un saut de 4 classes ou+ quel est le plafond du montant des dépenses éligibles à l’aide MaPrimeRénov’ Parcours Accompagné\xa0?',
        answers: {
          answer1: '20\xa0000\xa0€\xa0TTC',
          answer2: '40\xa0000\xa0€\xa0TTC',
          answer3: '70\xa0000\xa0€\xa0HT',
          answer4: 'Il n’y a pas de plafond maximal'
        }
      },
      question26: {
        question: 'Un des 3 principaux critères d’éligibilité à MaPrimeRénov’ Copropriété est de réaliser des travaux permettant un gain énergétique. De combien au minimum doit-il être\xa0?',
        answers: {
          answer1: '25%',
          answer2: '35%',
          answer3: '55%'
        }
      },
      question27: {
        question: 'Pour un logement individuel, quel est le montant maximal d’aides MaPrimeRénov’\xa0?',
        answers: {
          answer1: '20\xa0000\xa0€',
          answer2: '49\xa0000\xa0€',
          answer3: '63\xa0000\xa0€',
          answer4: 'Il n’y a pas de plafond maximal'
        }
      },
      questionMontantMar: {
        question: 'Le montant d’aides MaPrimeRénov dans le parcours accompagné dépend de\xa0:',
        answers: {
          answer1: 'Du revenu du ménage',
          answer2: 'Du nombre de sauts de classe énergétique',
          answer3: 'Du Bonus «\xa0Sortie de Passoire\xa0»',
          answer4: 'Du Bonus «\xa0Bâtiment Basse Consommation\xa0»',
          answer5: 'Du coût des travaux',
          answer6: 'De l’empreinte carbone des travaux'
        }
      },
      question30: {
        question: 'Quelle étape du processus de demande d’aides MaPrimeRénov’ peut déclencher le versement de la prime\xa0?',
        answers: {
          answer1: 'Réponse de l’ANAH',
          answer2: 'Réalisation des travaux',
          answer3: 'Demande de paiement de la prime',
          answer4: 'Contrôle de la demande'
        }
      },
      question1B: {
        question: 'Quelles sont les aides possibles pour une copropriété\xa0?',
        answers: {
          answer1: 'La rénovation globale (CEE)',
          answer2: 'La rénovation d’ampleur (parcours accompagné)',
          answer3: 'Les aides locales',
          answer4: 'MaPrimeRénov Copropriété',
          answer5: 'Eco-PTZ (éco-prêt 0%)',
          answer6: 'Aucune de ces aides'
        }
      },
      question2B: {
        question: 'Dans quel secteur la qualification «\xa0RGE\xa0» est obligatoire\xa0?',
        answers: {
          answer1: 'Commerce – Centre commerciaux',
          answer2: 'Café - Hôtellerie – Restauration ',
          answer3: 'Bâtiment tertiaire',
          answer4: 'Résidentiel individuel',
          answer5: 'Résidentiel collectif'
        }
      },
      question3B: {
        question: 'Quels sont les acteurs qui peuvent valoriser un dossier CEE\xa0?',
        answers: {
          answer1: 'Demandeurs CEE',
          answer2: 'Particuliers',
          answer3: 'Obligés',
          answer4: 'Mon Accompagnateur Rénov’ (MAR)',
          answer5: 'Les RAR'
        }
      },
      question4B: {
        question: 'Quelles sont les aides publiques\xa0?',
        answers: {
          answer1: 'MaPrimeRénov’',
          answer2: 'Les CEE',
          answer3: 'Les Coups de pouce',
          answer4: 'L’éco-PTZ (éco-prêt 0%)',
          answer5: 'TVA 5,5%'
        }
      },
      question5B: {
        question: 'Quels acteurs peuvent porter le Rôle Actif et Incitatif\xa0?',
        answers: {
          answer1: 'Les artisans et entreprises du bâtiments',
          answer2: 'Les obligés',
          answer3: 'Les bénéficiaires',
          answer4: 'Les éligibles',
          answer5: 'Le Pôle National CEE',
          answer6: 'Les délégataires'
        }
      },
      question6B: {
        question: 'Quelles sont les différences entre un délégataire et un éligible\xa0?',
        answers: {
          answer1: 'Ils portent tous les 2 le RAI mais l’éligible n’a pas d’obligation de production de CEE',
          answer2: 'Ils portent tous les 2 le RAI mais le délégataire n’a pas d’obligation de production de CEE',
          answer3: 'Ils portent tous les 2 le RAI mais l’éligible doit passer par un Mon Accompagnateur Rénov’ pour valoriser ses CEE'
        }
      },
      question7B: {
        question: 'Dans quel cadre le Mon Accompagnateur Rénov’ intervient-il\xa0?',
        answers: {
          answer1: 'Dans le cadre du parcours accompagné (MPR)',
          answer2: 'Dans le cadre du parcours par geste (MPR)',
          answer3: 'Dans le cadre d’un dossier CEE et de son coup de pouce',
          answer4: 'Dans le cadre d’une rénovation d’ampleur d’une résidence secondaire'
        }
      },
      question8B: {
        question: 'Quels sont les objectifs des Certificats d’Economies d’Energie\xa0?',
        answers: {
          answer1: 'Obliger les fournisseurs d’énergie à réaliser des économies d’énergie',
          answer2: 'Diminuer les émissions de gaz à effet de serre',
          answer3: 'Diminuer la consommation d’énergie',
          answer4: 'Construire de nouveaux logements'
        }
      },
      question9B: {
        question: 'Parmi les éléments suivants, lesquels sont inclus dans une démarche de rénovation énergétique\xa0?',
        answers: {
          answer1: 'La production d’électricité via des panneaux photovoltaïques',
          answer2: 'La production d’eau chaude via des panneaux solaires thermiques',
          answer3: 'La ventilation (VMC double flux)',
          answer4: 'L’isolation'
        }
      },
      question10B: {
        question: 'Par quelle aide la rénovation globale individuelle a été remplacée en 2024\xa0?',
        answers: {
          answer1: 'MaPrimeRénov’ Parcours accompagné',
          answer2: 'Le crédit d’impôt pour la transition énergétique',
          answer3: 'Habiter sain ',
          answer4: 'Rénovation globale accompagnée'
        }
      },
      question11B: {
        question: 'Quel type de ménages n’est pas éligible à MaPrimeRénov’ pour un geste de travaux\xa0?',
        answers: {
          answer1: 'Très modestes\xa0: bleu',
          answer2: 'Modestes\xa0: jaune',
          answer3: 'Intermédiaires\xa0: violet',
          answer4: 'Supérieurs\xa0: rose '
        }
      },
      question12B: {
        question: 'En quelle année la 6ème période va-t-elle commencer\xa0?',
        answers: {
          answer1: '2024',
          answer2: '2025',
          answer3: '2026',
          answer4: '2027'
        }
      },
      question13B: {
        question: 'Que signifie kWh cumac\xa0?',
        answers: {
          answer1: 'Energie Cumulée et actualisée',
          answer2: 'Energie Cumulée annuelle'
        }
      },
      question14B: {
        question: 'Quels éléments figurent dans une fiche d’opération standardisée\xa0?',
        answers: {
          answer1: 'La durée de vie',
          answer2: 'La dénomination',
          answer3: 'Une annexe',
          answer4: 'Le montant du certificat en kWh cumac',
          answer5: 'Le secteur d’application',
          answer6: 'Les conditions pour la délivrance de certificats'
        }
      },
      question15B: {
        question: 'Quelles sont les pièces nécessaires pour le dépôt d’une demande CEE pour une maison individuelle\xa0?',
        answers: {
          answer1: 'Attestation sur l’Honneur',
          answer2: 'Devis',
          answer3: 'Qualifications RGE',
          answer4: 'Cadre Contribution',
          answer5: 'Facture'
        }
      },
      question16B: {
        question: 'Quelles étapes sont nécessaires pour monter un dossier CEE dans le résidentiel individuel\xa0?',
        answers: {
          answer1: 'Prouver le Rôle Actif Incitatif (RAI) + Identifier la date l’engagement + Prouver la date d’achèvement de l’opération + Attestation de la fin des travaux',
          answer2: 'Identifier la date l’engagement + Prouver le Rôle Actif Incitatif (RAI) + Prouver la date d’achèvement de l’opération + Attestation de la fin des travaux '
        }
      },
      question17B: {
        question: 'Quel est le rôle du Pôle National des CEE (PNCEE)\xa0?',
        answers: {
          answer1: 'Instruire les dossiers',
          answer2: 'Délivrer les CEE',
          answer3: 'Aider au montage du dossier'
        }
      },
      question18B: {
        question: 'Par quels travaux est-il possible de produire des Certificats d’Economies d’Energie (CEE)\xa0?',
        answers: {
          answer1: 'Travaux d’isolation',
          answer2: 'La production d’électricité via des panneaux photovoltaïques',
          answer3: 'La production d’eau chaude via des panneaux solaires thermiques',
          answer4: 'Remplacement de chauffage/ECS',
          answer5: 'Mise en place de panneaux solaires',
          answer6: 'Travaux sur la ventilation'
        }
      },
      question19B: {
        question: 'Dans quelles situations les aides CEE sont-elles possibles\xa0?',
        answers: {
          answer1: 'Résidence de plus 15 ans',
          answer2: 'Résidence de plus de 10 ans',
          answer3: 'Résidence de plus de 2 ans',
          answer4: 'Résidence de moins de 2 ans'
        }
      },
      question20B: {
        question: 'Parmi les secteurs suivants lequel n’est pas concerné par des opérations standardisées\xa0?',
        answers: {
          answer1: 'Bâtiment neuf',
          answer2: 'Bâtiment résidentiel',
          answer3: 'Bâtiment tertiaire',
          answer4: 'Industrie'
        }
      },
      question21B: {
        question: 'Parmi les coups de pouce suivants, lequel n’existe plus\xa0?',
        answers: {
          answer1: 'Coup de pouce isolation',
          answer2: 'Coup de pouce chauffage',
          answer3: 'Coup de pouce pilotage connecté du chauffage pièce par pièce',
          answer4: 'Coup de pouce chauffage collectif résidentiel et tertiaire',
          answer5: 'Aucune réponse'
        }
      },
      question22B: {
        question: 'Quel type de bénéficiaire permet de produire des CEE précarité\xa0?',
        answers: {
          answer1: 'Un ménage modeste',
          answer2: 'Une résidence collective dans une zone dite « Quartier prioritaire de la ville » (QPV)',
          answer3: 'Un bailleur social',
          answer4: 'Une école dans une zone d’éducation prioritaire'
        }
      },
      question23B: {
        question: 'Quelles sont les conditions pour une demande de dossier MaPrimeRénov’\xa0?',
        answers: {
          answer1: 'Le bénéficiaire/Maître d’ouvrage doit initier la demande d’aide sur la plateforme MaPrimeRénov’',
          answer2: 'Il faut obligatoirement passer par un mandataire',
          answer3: 'Il faut faire les travaux par un artisan RGE',
          answer4: 'Il faut contractualiser avec un obligé ou délégataire'
        }
      },
      question24B: {
        question: 'Dans quels cas faut-il une durée minimale de 7 jours francs entre la signature du devis et le début des travaux dans le cadre des CEE\xa0?',
        answers: {
          answer1: 'Isolation',
          answer2: 'Chauffage',
          answer3: 'Rénovation globale',
          answer4: 'Dans le cas d’un contrôle'
        }
      },
      question25B: {
        question: 'Quel document est nécessaire au dépôt d’une demande CEE dans le secteur résidentiel\xa0?',
        answers: {
          answer1: 'Photos des travaux',
          answer2: 'Un diagnostic de performance énergétique (DPE)',
          answer3: 'L’avis d’imposition',
          answer4: 'Un contrat de performance énergétique (CPE)',
          answer5: 'Aucun de ces documents'
        }
      },
      question26B: {
        question: 'Quelle est la durée minimale entre la signature du devis et le début des travaux de pompe à chaleur dans le cadre des CEE\xa0?',
        answers: {
          answer1: 'Il n’y a pas de durée minimale',
          answer2: '5 jours ouvrés',
          answer3: '7 jours francs',
          answer4: '14 jours'
        }
      },
      question27B: {
        question: 'Qui peut faire appel à une aide MaPrimeRénov’ pour financer ses travaux\xa0?',
        answers: {
          answer1: 'Un propriétaire occupant',
          answer2: 'Un copropriétaire',
          answer3: 'Une Société Civile Immobilière (SCI)',
          answer4: 'Un propriétaire bailleur',
          answer5: 'Un locataire',
          answer6: 'Un propriétaire aux revenus aisés',
          answer7: 'Un bailleur social'
        }
      },
      question28B: {
        question: 'Dans le cas de MaPrimeRénov’ parcours accompagné, l’écrêtement sera réalisé sur',
        answers: {
          answer1: 'L’aide CEE',
          answer2: 'L’aide MaPrimeRénov’',
          answer3: 'Aucune des deux'
        }
      },
      question29B: {
        question: 'Dans les 2 parcours de MaPrimeRénov’, (accompagné et par geste) quels sont les montants maximaux d’aides\xa0?',
        answers: {
          answer1: 'Par geste : 20\xa0000\xa0€; Accompagné\xa0: 63\xa0000\xa0€',
          answer2: 'Par geste : 63\xa0000\xa0€; Accompagné\xa0: 63\xa0000\xa0€',
          answer3: 'Par geste : 20\xa0000\xa0€; Accompagné\xa0: 70\xa0000\xa0€',
          answer4: 'Il n’y a pas de plafond'
        }
      },
      question30B: {
        question: 'Le montant d’aides MaPrimeRénov’ dans le parcours accompagné dépend de\xa0:',
        answers: {
          answer1: 'De l’empreinte carbone des travaux',
          answer2: 'Du revenu du ménage',
          answer3: 'Du saut de classe énergétique',
          answer4: 'Du Bonus « Bâtiment Basse Consommation »',
          answer5: 'Du coût des travaux',
          answer6: 'Du Bonus «\xa0Sortie de Passoire\xa0»'
        }
      }
    }
  }
}

export default trainingMessages
